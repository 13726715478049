<template>
  <div>
    <div id="pageRegistPassword" class="mainContents">
      <section class="titleArea">
        <h2>パスワード再設定</h2>
        <!-- /.titleArea -->
      </section>

      <template v-if="!formSubmitted">
        <form>
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>

          <div class="passwordArea">
            <div class="inner">
              <dl>
                <div>
                  <dt>メールアドレス</dt>
                  <dd class="inputWrap">
                    <input v-model="email" type="email">
                  </dd>
                </div>
              </dl>
              <div class="buttonArea">
                <p>
                  <button
                    type="submit"
                    :disabled="formInvalid"
                    @click="reminder($event)"
                  >
                    メールを送信
                  </button>
                </p>
                <!-- /.buttonArea -->
              </div>
            </div>
            <!-- /.passwordArea -->
          </div>
        </form>
      </template>
      <template v-else>
        <div class="passwordArea">
          <div class="inner">
            <div class="updateComplete">
              <p class="text">
                登録したメールアドレスに、パスワード設定の案内メールをお送りしました。
                メールを確認し、6時間以内にパスワードの再設定を完了してください。
              </p>
              <!-- /.updateComplete -->
            </div>
          </div>
        </div>
        <!-- /.passwordArea -->
      </template>
      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import { fieldNotNull, validEmail } from '@/helpers/validator.js'

export default {
  data () {
    return {
      isUploading: false,
      email: '',
      error: null,
      formSubmitted: false
    }
  },
  computed: {
    hasError: function () {
      if (this.error !== null) {
        return true
      }
      return false
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validEmail) {
        return true
      }

      return false
    },
    validEmail: function () {
      return fieldNotNull(this.email) && validEmail(this.email)
    }
  },
  methods: {
    reminder (event) {
      event.preventDefault()
      this.isUploading = true
      this.error = null

      this.$store
        .dispatch('reminder', {
          email: this.email
        })
        .then(() => {
          this.isUploading = false
          this.formSubmitted = true
        })
        .catch(err => {
          this.error = err.response.data.error
        })
    }
  }
}
</script>

<style scoped></style>
